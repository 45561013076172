.error-main {
    h1 {
        font-size: 2.4em;
        text-transform: uppercase;
        font-weight: var(--font-weight-regular);
    }

    h2 {
        font-size: 1.5em;
    }

    p,
    .error-list-options {
        font-size: 1.5em;
        padding-bottom: 10px;
    }

    .error-list-options {
        padding-left: 20px;
        li {
            padding-bottom: 5px;
            list-style: disc;
        }

        a {
            color: var(--site-mid-grey);
            &:hover {
                color: var(--site-black);
            }
        }
    }

    .error-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 30px;
        max-width: 700px;
        a {
            background-color: var(--site-dark-grey);
            border: 1px solid var(--site-dark-grey);
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            padding: calc(3 * var(--padding-unit));
            letter-spacing: var(--default-letter-spacing);
            transition: 300ms ease-out;
            span {
                display: inline-flex;
                font-size: 1.3em;
                text-transform: uppercase;
                color: var(--site-white);
                letter-spacing: 0.03em;
                font-family: var(--default-font-family);
            }

            &:hover {
                background-color: var(--site-white);
                border: 1px solid var(--site-black);
                transition: 300ms ease-out;
                span {
                    color: var(--site-black);
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        margin: 50px auto;
        padding: 0 calc(7.5 * var(--padding-unit));

        .error-nav a {
            width: calc(50% - 5px);
        }
    }
}

footer.error-footer {
    margin: 30px auto 0;
}

.socialLinksWrapper {
    max-width: 1200px;
    border-bottom: 0;
    ul {
        display: flex;
    }

    li {
        display: flex;
        width: 25%;
        text-align: center;
        justify-content: center;
        a {
            background-image: var(--spritesheet);
            text-indent: -9999px;
            background-repeat: no-repeat;
            background-size: 1200px 1200px;
            width: 50px;
            height: 50px;
            max-width: 50px;
        }
    }

    li.socFacebook a {
        background-position: -1007px -568px;
    }

    li.socInstagram a {
        background-position: -1064px -568px;
    }

    li.socTwitter a {
        background-position: -950px -634px;
    }

    li.socTikTok a {
        background-position: -1121px -568px;
    }

    li.socPinterest a {
        background-position: -952px -568px;
    }

    li.socSpotify a {
        background-position: -90px -673px;
    }

    @media screen and (min-width: 768px) {
        width: 25%;

        li {
            justify-content: flex-start;
        }
    }
}
