.global-icon-tick .tick-outer:not(.wishlist-share .global-icon-tick .tick-outer) {
    fill: var(--site-green);
    stroke-width: 0;
}

.global-icon-tick .tick-inner {
    fill: var(--site-black);
    stroke-width: 0;
}

.modal .close,
.reviews-container .bv-cv2-cleanslate .bv-content-search .bv-search-control-icon {
    background-image: var(--spritesheet) !important;
    content: "";
    display: inline-block;
    width: 39px;
    height: 39px;
    background-size: 900px 900px !important;
    background-position: -535px -426px !important;
    cursor: pointer;
    background-color: transparent !important;
    border: none;
    right: 5px;
    top: 5px;
}

a.bx-prev,
a.bx-next {
    width: 45px;
    height: 45px;
    background-position: -13.2em -46.6em;
    background-size: 75em 75em;
}

.bx-prev::after,
.bx-prev::before,
.bx-next::after,
.bx-next::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
}

a.bx-prev {
    left: 10px;
}

a.bx-next {
    background-position: -19.4em -46.6em;
    right: 10px;
}

.bx-prev:hover,
.bx-next:hover {
    background-color: transparent;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: var(--site-white);
    width: 16px;
    height: 16px;
    border-radius: 0;
    border: solid 1px #666;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #0054a4;
}

.hmeSlideImg,
.divCTA,
.divSpacer {
    top: 0;
}

.hmeSlideImg {
    z-index: 0;
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.divAdTable {
    width: 100%;
    display: block;
    height: 100%;
    float: left;
    z-index: 2;
    position: absolute;
    bottom: 0;
}

.divAdTable a {
    color: var(--site-white);
}

.divCTA {
    text-align: center;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 2% 0 0;
}

.aCTAlink {
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 20%;
    text-transform: uppercase;
    color: var(--site-white);
    transform: translate3d(0, 0, 0);
    width: 100%;
}

a.aCTAlink:hover {
    color: var(--site-white);
}

.imgCopy {
    text-transform: uppercase;
    color: var(--site-white);
    margin: 10% 0 1%;
}

li:hover > .hmeSlideImg {
    transition: 0.5s ease-in-out;
    opacity: 0.8;
}

/*-- three callout row --*/
.callOutAd {
    display: block;
    position: relative;
}

.callOutInnerWrap {
    overflow: hidden;
    position: relative;
}

.callOut img {
    padding-bottom: 10px;
    z-index: 0;
}

.callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.callOutCTA,
.sliderTwo .callOutCTA {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 80px;
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 0;
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 0;
}

.callOutCTA a,
.sliderTwo .callOutCTA a {
    display: block;
    width: 100%;
    height: 100%;
}

.callOutAd:hover > .callOutInnerWrap > .callOutimg,
.sliderTwo li div:hover > .callOutInnerWrap > .callOutimg,
.sliderThree li .slideCell:hover > div > .callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 0.6;
    cursor: pointer;
}

.callOutCTA span,
.sliderTwo .callOutCTA span {
    width: 100%;
    text-transform: uppercase;
    float: left;
    text-align: center;
    transform: translate3d(0, 0, 0);
}

.callOutCat {
    font-size: 2.3em;
    line-height: 1em;
}

.callOutCat,
.callOutSubTxt {
    color: var(--site-white);
}

.sliderTwo img {
    padding-bottom: 10px;
}

.tab {
    position: relative;
}

span.prodPrice {
    font-size: 1.1em;
}

.hpTrendingTxt {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 1px;
    color: #747474;
    margin-top: 10px;
}

.tabSwitch .inActive {
    background: transparent;
    color: #747474;
}

.tabSwitch .inActive:hover {
    background-color: transparent;
    color: #747474;
    text-decoration: none;
}

.tabSwitch .active {
    background: transparent;
    color: #0054a4;
}

.tabSwitch .active::before,
.tabSwitch .inActive::before {
    content: "";
    display: block;
    height: 5px;
    width: 50%;
    position: absolute;
    background-color: #0054a4;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.tabSwitch .inActive::before {
    background-color: transparent;
}

.tabSwitch .inActive:hover::before {
    background-color: #747474;
}

.hotspotTooltip {
    display: block !important;
}

.hotspotTop,
.hotspotBottom {
    text-align: center;
}

#One,
#Two {
    width: auto;
    float: left;
    padding: 10px 60px;
    border: 0;
    font-size: 1.2em;
}

#One:hover,
#Two:hover {
    cursor: pointer;
}

h3.prodTitle:hover {
    color: #0054a4;
}

.carouselIcon {
    padding-right: 4px;
}

.bxCarouselA li,
.bxCarouselB li {
    width: 20%;
    float: left;
}

.hpSlideInner {
    display: block;
    overflow: hidden;
}

.slideCell {
    padding-bottom: 9999px;
    margin-bottom: -9999px;
}

.noPadding {
    padding: 0;
}

.botMargin10 {
    margin-bottom: 0;
}

.sliderThree img {
    padding-bottom: 10px;
}

.sliderThree .lgeImgWrap .callOutimg {
    margin-bottom: 0;
}

.lgeImgWrap {
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    margin-bottom: -10px;
}

@media (max-width: 767px) {
    #retSlideArrow,
    #advSlideArrow,
    #retSlide2Arrow,
    #advSlide2Arrow,
    #retSlide3Arrow,
    #advSlide3Arrow {
        display: none;
    }

    .bx-wrapper .bx-pager.bx-default-pager a {
        margin: 0 10px;
        border-radius: 0;
    }

    .slideCell {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    #mp-menu .mp-level ul li.level2.disablelink > a.MobMenChevron {
        pointer-events: none;
    }
}

@media (min-width: 768px) {
    .divCTA {
        text-align: left;
        display: block;
        height: 100%;
        padding: 11% 0 0 7%;
    }

    .imgCopy {
        margin: 0 0 18%;
    }

    .largeTxt.imgCopy {
        margin-top: 11%;
    }

    .aCTAlink {
        width: auto;
    }

    .leftSlide {
        padding-left: 0;
    }

    .rightSlide {
        padding-right: 0;
    }

    .sliderTwo img {
        padding-bottom: 0;
    }

    .botMargin10 {
        margin-bottom: 10px;
    }

    .sliderThree img {
        padding-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: -5px;
    }

    .nlPadding {
        padding-left: 0;
    }

    .nrPadding {
        padding-right: 0;
    }
}

.SD_NEW_ROWFIVE .callOut {
    margin-top: 10px;
}

@media (max-width: 767px) {
    .noPadding {
        padding: 0;
    }

    .SD_NEW_ROWFIVE .callOut {
        margin-top: 0;
    }

    .SD_NEW_ROWTHREE .sliderWrapper {
        padding: 0;
        margin-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: 10px;
    }

    .bxslider2,
    .bxslider2 li {
        width: 100% !important;
    }
}

#Body {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
}

:focus {
    outline: 1px dotted #999;
}

.content {
    border: 12px solid var(--site-white);
}

.byPostGroup,
#contactuscontent select,
#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
.csHomeTable,
#divBagItems {
    border: 1px solid #ccc;
}

.prc_commentpostingpanelcommentsectiontextboxcomment,
.prc_ModShow_Textbox {
    border: 2px solid #ccc;
}

.DontForgetText {
    border-bottom: 1px solid #b2b2b2;
}

.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripTop_Vista .rtsLevel .rtsOut,
.RadTabStripBottom_Vista .rtsLevel .rtsLink {
    background-image: none !important;
}

.whiteBoxBrand .greyBgBrand.boxContentBrand,
.whiteBoxBrand {
    background: none repeat scroll 0 0 var(--site-white) !important;
}

.whiteBoxBrand .boxContentBrand {
    border: none !important;
}

.c_BlackTitle h3 {
    border-bottom: 2px solid #ccc;
}

.relatedquestions h4 {
    border-bottom: 1px dotted #ccc;
}

hr {
    border-top: 1px solid #ccc;
}

.ui-slider .ui-slider-handle {
    border: 1px solid #999;
}

.productFilter-slider {
    border: 0;
}

#pnlWebPercentOff {
    padding-top: 5px;
}

.weWrap {
    font-size: 1em;
}

.infoTabPage h2,
.infoaccordion .panel-body h2 {
    font-size: 1.1em;
}

.HelpIco {
    background-position: -11px -194px;
    background-size: 500px 500px;
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a {
    border: 0;
}

.SliderContain .ui-slider-horizontal .ui-slider-handle {
    margin-left: -3px;
    margin-right: 0;
}

.fieldcheckbox {
    width: 62%;
}

#address-sec {
    margin: 0;
}

.dontForget,
.dontForget img {
    width: 100%;
    height: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a {
    border: 1px solid #0463c4;
}

.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a {
    border: 1px solid var(--site-black);
}

#navigation-container {
    margin: 1% 0 2%;
}

.wrapper h1 {
    overflow: hidden;
}

.MenuGroupGAME {
    display: block;
}

.MenuGroupUSC {
    display: block;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink {
    line-height: normal;
    float: none;
    font-size: 0.8rem;
    padding: 0 10px 0 0;
    order: 2;
    color: var(--site-white);
}

.FooterPane,
.FooterPane2 {
    padding: 0;
}

ul.StoreList li {
    width: auto;
    padding: 0 6px;
    float: left;
}

ul.StoreList {
    height: 26px;
    display: inline-block;
    padding-right: 17px;
}

ul.StoreList li a {
    color: #0051a1;
}

ul.StoreList li a:hover {
    text-decoration: underline;
    color: #ef2010;
}

.RightBarLinks div.TopLinkMenu > a span {
    color: var(--site-black);
    font-size: 14px;
    display: inline-block;
    line-height: 1.4em;
}

.ASCdelivery2 .FullAdvert {
    margin-bottom: 5px;
}

.FourHovers ul li > a {
    margin-bottom: 0;
}

.FourHovers ul li > div {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.advert .swiper-container {
    margin: 0 auto 20px;
}

.Home .ContentWrapper {
    padding: 0;
    max-width: 1920px;
}

section.mainBody > .row > div {
    min-height: 0;
}

#findAstore a {
    padding-top: 12px;
    display: block;
    padding-left: 22px;
    height: 100%;
    font-size: 1.1em;
}

.storefind {
    display: block;
    height: 40px;
}

.basketLink {
    height: 40px;
    padding: 0;
}

.PopToRight,
.CardsSocial,
#dnn_footerPaneR {
    display: none;
}

.coprightmega {
    text-align: left;
}

.Responsive .coprightmega {
    padding-top: 7px;
}

li.shopAllLink a {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: bold !important;
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColHeaderHidden {
    height: 32px;
    border-bottom: 1px solid #bbb;
}

li.sdmColTopHeader a {
    font-weight: bold !important;
    font-size: 1.3em !important;
    text-decoration: underline;
}

.megacards span {
    margin: 10px 0;
}

.back-to-top {
    display: none !important;
}

.s-breadcrumbs-container .s-breadcrumbs-bar {
    font-size: 1.1em;
}

.giftCardCategory #divColour .s-productextras-column-1::before {
    content: "Occasion:";
    font-family: raleway, Arial, Helvetica, sans-serif;
    font-size: 1.2em;
}

.giftCardCategory #divSize .s-productextras-column-1::before {
    content: "Amount:";
    font-family: raleway, Arial, Helvetica, sans-serif;
    font-size: 1.2em;
}

.giftCardCategory .s-productextras-column-1 #BuyColourText,
.giftCardCategory .s-productextras-column-1 .BuySizeText,
.giftCardCategory #divPopupSizeGuide,
.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a span,
.hideSizeGuide #divPopupSizeGuide {
    display: none;
}

.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a::before {
    content: "select an amount";
}

.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer.sizeSelected a::before {
    content: "ADD TO BAG";
}

.stackFlexHeader {
    padding: 20px 15px 30px;
    width: 100%;
}

.stackFlexHeader h3 {
    margin: 0;
    padding: 5px 0;
}
.FontBold {
    font-weight: bold;
}

.emailSignupPane {
    padding: 0;
}

.socialMediaContainer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.txtLink {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    padding-left: 5px;
}

.txtLink:hover {
    text-decoration: underline;
}

.max-width {
    margin: 0 auto;
    max-width: 1400px;
}

.sitewideBanner .closingIcon {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 5px;
}

.sitewideBanner .closingIcon div {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-size: 700px 700px;
    background-position: -411px -286px;
    cursor: pointer;
}

.sitewideBanner .swiper-container-sitewideBanner {
    border: 0;
    height: 64px;
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 0;
    transition: all 0.25s ease;
    transform: translateZ(0);
    will-change: tranform;
    backface-visibility: hidden;
    margin: 0 auto;
    overflow: hidden;
    list-style: none;
    padding: 0;
}

@media (min-width: 768px) {
    .sitewideBanner .swiper-container-sitewideBanner {
        width: 50%;
        max-width: 550px;
        height: 46px;
    }
}

.sitewideBanner .swiper-slide:nth-child(odd) {
    background-color: #ed0202;
}

.sitewideBanner .swiper-slide:nth-child(even) {
    background-color: #0900ed;
}

.sitewideBanner .swiper-slide .swiper-text {
    color: var(--site-white);
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 12px;
}

.sitewideBanner .swiper-slide a {
    width: 100%;
    height: 100%;
    padding: 14px 40px 4px;
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .sitewideBanner .swiper-slide .swiper-text {
        text-align: left;
        font-size: 14px;
    }

    .sitewideBanner .swiper-slide a {
        justify-content: space-between;
        padding: 0 40px 4px 15px;
    }

    .sitewideBanner .closingIcon {
        top: 1px;
    }
}

@media (max-width: 767px) {
    .sitewideBanner .swiper-slide a {
        padding: 0 40px 4px;
    }

    .sitewideBanner .swiper-slide .swiper-text {
        display: flex;
        flex-direction: column;
    }

    .sitewideBanner .closingIcon {
        top: 10px;
    }
}

.promotionBanner .swiper-slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: translate(0, 0);
}

.sitewideBanner .swiper-slide:nth-child(even)::after {
    background-color: hsla(0, 100%, 46%, 1);
}

.sitewideBanner .swiper-slide:nth-child(odd)::after {
    background-color: hsla(244, 100%, 46%, 1);
}

.sitewideBanner .swiper-slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: translate(-100%, 0);
}

.sitewideBanner .swiper-slide-active::after {
    transition: transform 5s linear;
    transform: translate(0, 0);
}
/*end of sitewide*/

/*Footer search for stores*/
.CountrySelection > div,
.CountryNoSelection > div,
.CountryNoEnglish > div {
    width: 100%;
}

#SearchStores a,
#SearchStores li {
    width: 100%;
    height: 198px;
    display: block;
    margin: 0 auto;
    max-width: 165px;
}

#SearchStores li {
    height: 180px;
    background: url("/images/core/footer-stores-background-v2.png") no-repeat;
}

.hotspotbuy.hotspotwishlist,
.hotspotbuy.hotspotquickbuy {
    width: 50%;
    height: 35px;
    background-color: rgba(220, 220, 220, 0.9);
    overflow: hidden;
    font-size: 1.1em;
}

.CountrySelection p,
.CountryNoSelection p,
.CountryNoEnglish p {
    color: #686868;
    font-size: 1.4em;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 1.2em;
    padding-bottom: 6px;
}

#SearchStores li .FooterHeader {
    color: #686868;
    font-size: 1.3em;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    width: 45%;
}

div.NewMenu {
    padding: 0;
    width: 100%;
    max-width: 1410px;
    height: 100%;
}

div.NewMenuWrap ul > li.level1:hover {
    background-color: transparent !important;
}

div.NewMenuWrap ul > li.sdHover > a,
.NewMenuWrap li.level2 a:hover {
    background-color: rgba(0, 84, 164, 0.9) !important;
}

div.NewMenu > ul > li > a {
    padding: 0 45px;
}

li.menuImg {
    background: url("/images/marketing/menuimage.jpg") no-repeat;
    min-height: 340px;
    display: block;
    height: 100%;
    text-indent: -9999px;
}

.DropdownWrap {
    left: 17% !important;
    width: 83% !important;
    padding: 10px;
    top: 0;
    position: absolute;
    height: 100%;
    overflow: hidden !important;
}

.Dropdown {
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    padding: 0;
    max-width: 100%;
    background: transparent;
}

.Dropdown .Center {
    width: 100%;
    background: transparent;
    padding: 0;
}

.Dropdown .Center .columnGroup {
    width: 20%;
}

.Dropdown .Center > ul > li:first-child + li + li + li {
    width: 20%;
    clear: none;
}

nav#topMenu > ul .NewMenuWrap li.level2 a {
    line-height: 1em;
    padding: 5px;
    max-width: 100%;
    font-size: 1.3em;
}

/* Popular Menu */
.popularMenu h3 {
    font-size: 0.8em;
    line-height: 1.1em;
    text-transform: uppercase;
    padding: 15px 0 15px 16px;
    margin: 0;
}

.popularMenu h3,
nav#topMenu li.root .NewMenuWrap .popularMenu a {
    color: var(--site-white);
}

.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle {
    text-align: left;
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: 900;
    padding: 10px 0 30px;
    border: 0;
    margin: 0;
    font-size: 1.7em;
    letter-spacing: 0.05em;
    color: var(--site-black);
}

.sdDetails .PSPlacementWrapper,
.sdDetails #pnlRecentlyViewedProducts {
    border: 0;
    margin: 4px 0 0;
}

.sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
.sdDetails #pnlRecentlyViewedProducts .AltVertOne {
    margin: 0;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg {
    display: block;
    padding: 0;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg img {
    max-width: none;
    width: 100%;
}

.PSPlacementWrapper .ProductSuggestionsListing li a {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct > h4,
#pnlRecentlyViewedProducts h4 {
    text-align: left;
    font-size: 1em;
    font-weight: normal;
    padding: 0;
}

.PSPlacementWrapper .SuggestedProduct .PSProdPrice,
#pnlRecentlyViewedProducts .AltStratRefSell {
    padding: 5px 5px 0;
    text-align: left;
    height: auto;
}

.PSPlacementWrapper .SuggestedProduct .productimage.s-productthumbimage {
    padding: 5px 10px;
}

.PSPlacementWrapper .productimage .PSImage .PSPimg-res {
    max-width: 180px;
    width: 100%;
}

.PSPlacementWrapper .SuggestedProduct .PSSellPrice,
#pnlRecentlyViewedProducts .AltStratSellPrice {
    letter-spacing: 0.05em;
    font-size: 1.2em;
    display: inline-block;
    line-height: 1.1em;
}

.PSPlacementWrapper .SuggestedProduct .PSFrom,
#pnlRecentlyViewedProducts .AltFrom {
    font-size: 1.1em;
    display: inline-block;
    line-height: 1.2em;
    padding-right: 2px;
}

.PSPlacementWrapper .SuggestedProduct .PSFromSell,
#pnlRecentlyViewedProducts .AltFromSell {
    display: inline-block;
    line-height: normal;
    padding-right: 5px;
}

#pnlRecentlyViewedProducts .AltStratProduct {
    margin-bottom: 0;
}

#pnlRecentlyViewedProducts .AltStratBrandDesc {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct .PSTicketSell,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPriName {
    padding: 0;
    line-height: normal;
}

.PSPlacementWrapper .PSProdPrice .AdditionalPriceLabel,
#pnlRecentlyViewedProducts .AltStratRefSell .AdditionalPriceLabel {
    padding: 0 2px 0 0;
    line-height: 1.2em;
    font-size: 1.1em;
    color: #888;
    display: inline-block;
}

.PSPlacementWrapper .SuggestedProduct .ticketPricePrefix,
.PSPlacementWrapper .SuggestedProduct .PSRefPrice,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPrice {
    display: inline-block;
    font-size: 1.1em;
    line-height: 1.2em;
}

.SuggestedProduct .hotspotbuy.hotspotquickbuy,
.SuggestedProduct .hotspotbuy.hotspotwishlist {
    top: 0;
    bottom: auto;
}

@keyframes skeleton {
    0% {
        background-color: #d9d9d9;
    }

    40% {
        background-color: #eee;
    }

    80% {
        background-color: #d9d9d9;
    }

    100% {
        background-color: #d9d9d9;
    }
}

.sdDetails .productImageContentWrapper,
.evanDetails .productImageContentWrapper {
    z-index: 1;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn.OrderCompleteRegisteredSignUpButton {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .sdlProdList .catTemplateNew .collapse {
        display: block !important;
        height: auto !important;
    }

    .catTemplate .textLeft {
        margin: 35px 0 15px;
        color: #999;
        line-height: 1.8em;
        padding: 0 5%;
        letter-spacing: 0.1em;
    }

    .catTemplate-FWC .siblingLinks a {
        padding: 12px 20px;
    }

    #delivery_Nextday .TitleDel,
    #NextDayDelivery .TitleDel {
        width: calc(100% - 40px);
    }
}

@media (max-width: 767px) {
    .catTemplate.catTemplateNew .siblingLinks {
        padding-top: 15px;
    }

    .catTemplate-FWC .siblingLinks a {
        margin: 0 10px 10px 0;
    }
}

.ui-autocomplete .header {
    margin-bottom: 5px;
}

.ui-autocomplete > li > .ui-corner-all {
    font-size: 1.2em;
    padding: 10px;
    font-weight: 400;
}

.RightBarLinks div.TopLinkMenu > a,
.Responsive .IconBagandWish {
    transition: background-color 0.3s ease-in;
}

@media (min-width: 1022px) {
    .ResponsiveSwitch {
        display: none;
    }

    /* PDP Elevation 21 */
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        flex: 0 0 calc(50% - 5px);
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayLess3 .innerImageContainer a,
    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .gridDisplay3
        .innerImageContainer
        a:nth-of-type(1) {
        flex: 0 0 100%;
    }
}

@media (max-width: 1021px) {
    .recently-viewed-swiper-container {
        overflow: visible;
    }

    #pnlRecentlyViewedProducts .AltVertOne .AltStratProduct {
        width: 200px;
    }

    .ChildCategoriesListWrapper + .refineByLeftHeader .mobDdClose {
        top: auto;
        bottom: 0;
    }

    .Responsive .mobDdClose {
        width: 40px;
        line-height: 40px;
        height: 40px;
        padding: 0;
        border: 0;
    }

    .desktopNav {
        display: none;
    }

    nav#topMenu ul li.root > a {
        font-size: 1em;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .FooterWrap .txtLink,
    .FooterColLink2 {
        display: none;
    }

    .Responsive .currencyLanguageSlider {
        right: 5px;
    }

    #divBag .HeaderBagEmptyMessage {
        font-size: 1em;
    }

    #divBag .HeaderCheckoutLink {
        font-size: 1.1em;
        letter-spacing: 0.1em;
    }

    .footerMenu > .row {
        width: 32.5%;
        float: left;
    }

    .footPopLink ul {
        width: 100%;
    }

    .socialMediaContainer li {
        padding: 4px 0 !important;
    }

    .basketLink .langlogin .login,
    #findAstore a {
        text-indent: -9999px;
    }

    #navigation-container {
        margin: 2% 0 4%;
    }

    .LanguageCurrency {
        float: right;
    }

    .storefind:hover::before {
        background-position: -97px -99px;
    }

    .storefind::before {
        left: 50%;
        transform: translateX(-50%);
        background-position: -32px -99px;
    }
}

@media (max-width: 1021px) {
    .sub-buttons {
        margin: 0;
    }

    .shop > ul > li.root.mobOnly.appExcl > a {
        background-color: #f9f210;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.2em;
    }

    .shop > ul > li.root.mobOnly.appExcl > a > .menuitemtext::after {
        color: #ed0000;
        content: " 20% off everything";
    }

    .am-menu ul li.level2.botList a {
        background-color: var(--site-white);
    }

    .bsASB {
        padding-top: 6px;
        border-bottom: 2px solid #323232;
        text-align: center;
    }

    .Responsive .IconBagandWish:hover {
        background-color: #fafafa;
    }

    #divBagTotalLink span#bagQuantity {
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 1.5em;
    }

    #divBagTotalLink a::before {
        background-position: -20px -339px;
    }

    .coprightmega {
        text-align: center;
    }

    .Basket .ads {
        display: none;
    }

    .csHomeTable {
        min-height: 275px;
    }

    .socialtext {
        display: none;
    }

    .MobileMenuContentWrap .MobMenIcon {
        display: none;
    }

    .MobileMenuContentWrap > ul li,
    .MobileMenuContentWrap .has-dropdown li.level1 > a,
    .MobileMenuContentWrap #liMobileLanguageSelector,
    .MobileMenuContentWrap #liMobileCurrencySelector,
    .MobileMenuContentWrap > ul li:hover,
    .MobileMenuContentWrap #liMobileLanguageSelector:hover,
    .MobileMenuContentWrap #liMobileCurrencySelector:hover {
        background-color: transparent;
    }

    li#mob-signout a {
        color: #fd0000 !important;
    }

    .logged-in .mp-container .mp-menu .mp-level ul li#mob-signout {
        display: block;
    }

    li#mob-signout {
        display: none;
    }

    .Responsive .categoryListSide h2 {
        background: var(--site-white);
    }

    .am-menu ul li.level2 a {
        padding-left: 30px;
    }

    .am-menu ul li.level2.indentSub a {
        padding-left: 35px;
    }

    .am-menu ul li.level2.sdmColHeader a {
        padding-left: 25px;
        background-color: #f0f0f0;
    }

    .has-dropdown ul li.mobOnly.mobMenToplink:first-of-type {
        display: none;
    }

    ul.mobMenuGroup.account-menu,
    .mp-menu > .mp-level .languageSelectorMobile,
    .mp-menu > .mp-level #divCurrencyLanguageMobile .currencySelectorMobile {
        background-color: var(--site-white);
    }

    .mp-menu .mp-level #mobile-menu-footer a.MobMenChevron.open::after,
    .mp-menu .mp-level #mobile-menu-footer-content a.MobMenChevron.open::after {
        background-position: -366px -362px;
    }

    .shop #mob-newsletter {
        display: none;
    }

    #mp-menu .mp-level .mobMenSplit,
    #mp-menu .am-level .mobMenSplit {
        position: relative;
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .mp-level .mobMenSplit::after,
    #mp-menu .am-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::after {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, #ee3424 50%, #0000ed 50%);
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::before {
        background: var(--site-white) !important;
        z-index: 0;
    }

    .MenuGroupBrands > .mp-level > ul {
        display: flex;
        flex-wrap: wrap;
    }

    .MenuGroupBrands .mp-level ul li {
        width: 100%;
    }

    .MenuGroupBrands > .mp-level ul li.brandsGrid {
        width: 50%;
    }

    .MenuGroupBrands .mp-level ul li.brandsGrid > a {
        text-indent: -9999px;
        padding: 0;
        font-size: 0 !important;
        margin-left: 0;
        width: 100%;
    }

    .brandsGrid .mobImg::before {
        z-index: 2;
    }

    .brandsGrid .mobImg .overlayMenuBrands {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

nav#topMenu ul li.root.saleRed > a,
nav#topMenu ul li.root.saleRed > a:hover {
    background-color: #f00;
    color: var(--site-white);
}

#mp-menu .mp-level .saleRed,
#mp-menu .mp-level .saleRed > .menuitemtext {
    background-color: #f00;
    color: var(--site-white);
}

.mp-menu .mp-level .saleRed a.MobMenChevron::after {
    background-position: -394px -319px;
}

/*--menu advert --*/
.singlecolumnadvert .view-button {
    display: block;
    font-size: 0.9em;
    background-color: #ccc;
    padding: 15px;
    text-align: center;
}

.modal .close:hover {
    background-color: transparent !important;
}

#advertPopup .close {
    background-position: -259px -487px !important;
}

#advertPopup .close:hover {
    background-position: -302px -487px !important;
}

.MagazineModal .close {
    width: 39px;
    height: 39px;
}

.MagazineModal .close,
#advertPopup .close {
    background-color: var(--site-white) !important;
}

.MagazineModal .close:hover,
#advertPopup .close:hover {
    background-color: var(--site-white) !important;
}

/* App forced product */
#BasketModal.appPromoMug .modal-dialog {
    max-height: 500px;
    max-width: 400px;
}

#BasketModal.appPromoMug .modal-body {
    height: 450px;
    max-height: 450px;
    overflow-x: visible;
    overflow-y: visible;
    padding: 15px 25px 20px;
}

#BasketModal.appPromoMug #workarea {
    border: none;
}

#BasketModal.appPromoMug .modal-header .header-text {
    display: none;
}

.forcedProductInnerContent {
    padding-top: 100px;
}

.forcedProductInnerContent .forcedProductImage {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("/images/marketing/mugshot.png");
    height: 200px;
    width: 200px;
    background-size: contain;
    position: absolute;
    top: -95px;
    left: 50%;
    margin-left: -119px;
}

.forcedProductInnerContent .forcedProductContentTitle {
    text-transform: uppercase;
    font-size: 2.7em;
    font-weight: normal;
    letter-spacing: 2px;
}

.forcedProductInnerContent .forcedProductContentTitle span {
    font-weight: bold;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar,
.forcedProductInnerContent .forcedProductContentTitle .lStar {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: top;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 22em 22em;
    background-position: -2.2em -6.4em;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar {
    margin-left: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar {
    margin-right: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before,
.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    content: " ";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 15em 15em;
    background-position: -1.55em -4.39em;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before {
    left: -18px;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    right: -18px;
}

.forcedProductInnerContent p {
    font-size: 1.5em;
    font-style: italic;
    margin-bottom: 45px;
}

.forcedProductInnerContent p.forcedProductAddMessage {
    font-weight: bold;
    font-style: normal;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.forcedProductInnerContent p.forcedProductAddMessage span {
    text-transform: uppercase;
}

.forcedProductInnerContent p.discountMessage {
    font-size: 1.1em;
    margin-bottom: 20px;
}

#BasketModal.appPromoMug .buttoncontainer {
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap {
    display: block;
    margin-top: 15px;
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a {
    padding: 18px 15px;
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.5em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a::before {
    content: "";
    display: inline-block;
    width: 27px;
    height: 20px;
    vertical-align: sub;
    margin-right: 10px;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 30em 30em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child a::before {
    background-position: 9.95em 19.2em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child + .ImgButWrap a::before {
    background-position: 7.6em 17.2em;
}

@media (min-width: 768px) {
    #BasketModal.appPromoMug .modal-dialog {
        width: 400px;
    }
}

@media (max-width: 767px) {
    #BasketModal.appPromoMug .modal-body {
        height: 400px;
        max-height: 400px;
    }

    .forcedProductInnerContent {
        padding-top: 70px;
    }

    .forcedProductInnerContent .forcedProductImage {
        width: 150px;
        height: 150px;
        top: -78px;
        margin-left: -90px;
    }

    .forcedProductInnerContent p {
        margin-bottom: 25px;
    }
}

#advertPopup .modal-dialog {
    max-width: 634px;
    height: 100%;
    max-height: 634px;
}

#advertPopup .modal-body {
    max-width: 632px;
    height: 632px;
    max-height: 632px;
}

@media (max-width: 633px) {
    #advertPopup .modal-dialog {
        max-height: 633px;
    }

    #advertPopup .modal-body {
        height: 631px;
        max-height: 631px;
    }
}

@media (max-width: 594px) {
    #advertPopup .modal-dialog {
        max-height: 594px;
    }

    #advertPopup .modal-body {
        height: 592px;
        max-height: 592px;
    }
}

@media (max-width: 549px) {
    #advertPopup .modal-dialog {
        max-height: 549px;
    }

    #advertPopup .modal-body {
        height: 547px;
        max-height: 547px;
    }
}

@media (max-width: 504px) {
    #advertPopup .modal-dialog {
        max-height: 504px;
    }

    #advertPopup .modal-body {
        height: 502px;
        max-height: 502px;
    }
}

@media (max-width: 459px) {
    #advertPopup .modal-dialog {
        max-height: 459px;
    }

    #advertPopup .modal-body {
        height: 457px;
        max-height: 457px;
    }
}

@media (max-width: 414px) {
    #advertPopup .modal-dialog {
        max-height: 414px;
    }

    #advertPopup .modal-body {
        height: 412px;
        max-height: 412px;
    }
}

@media (max-width: 375px) {
    #advertPopup .modal-dialog {
        max-height: 375px;
    }

    #advertPopup .modal-body {
        height: 373px;
        max-height: 373px;
    }
}

@media (max-width: 348px) {
    #advertPopup .modal-dialog {
        max-height: 348px;
    }

    #advertPopup .modal-body {
        height: 346px;
        max-height: 346px;
    }
}

@media (max-width: 320px) {
    #advertPopup .modal-dialog {
        max-height: 320px;
    }

    #advertPopup .modal-body {
        height: 318px;
        max-height: 318px;
    }
}

.FastPayStatusBox p,
.FastPaySettingRowButtons div {
    color: #0154a4 !important;
}

/*--- new size guide override colours --*/
a.list-group-item:hover,
a.list-group-item:focus,
.primaryColor {
    background-color: #0054a4 !important;
    border-color: #0054a4 !important;
}

.list-group-item.list-group-submenu:hover,
.list-group-item.list-group-submenu:focus {
    background-color: #0054a4 !important;
}

.headRow .divtableCell {
    background-color: #0054a4 !important;
}

/* adi launch */
#superModal .modal-dialog {
    max-width: 902px;
    width: 100%;
    max-height: 370px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
}

#superModal .modal-body {
    height: 100%;
    width: 100%;
    padding: 0;
}

#superModal .modal-header {
    height: 0;
    border: 0;
    padding: 0;
}

#superModal #finalFrame {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#superModal #finalFrame img {
    margin-bottom: 0;
}

.catTemplate #videoCover {
    cursor: pointer;
    z-index: 2;
    transition: all 0.7s ease-in;
}

/*-- parallax page --*/
.parallax .TopLinkBar {
    height: 5px;
}

.parallax .TopNavBar {
    top: 5px;
}

.threeBox h2::before {
    background-position: -311px -284px;
}

.threeBox .cprefsSMS h2::before {
    background-position: -270px -353px;
}

.threeBox .cprefsPost h2::before {
    background-position: -350px -353px;
}

/* LIFT IGNITER */
.li-pr-home,
.li-pr-basket {
    display: block !important;
    padding: 0 0 20px;
}

#li_placement .hotspotbuy {
    position: absolute;
    top: 15px;
}

.li-pr-basket .swiper-container {
    padding-bottom: 10px;
}

#li_placement .hotspotbuy.hotspotquickbuy,
#li_placement .hotspotbuy.hotspotwishlist {
    right: 15px;
    height: 28px;
    width: 28px;
    background: transparent;
}

#li_placement .hotspotbuy.hotspotwishlist {
    right: 50px;
}

.SuggestedProduct h3 a:hover span {
    text-decoration: underline;
    color: #636363;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon {
    width: 28px;
    height: 28px;
    background-size: 66em 45em;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .hotspotbuy .QuickLookIcon {
    background-position: -23px -953px;
}

#li_placement .SuggestedProduct .QuickLookIcon:hover,
#li_placement .hotspotbuy .QuickLookIcon:hover {
    background-position: -67px -953px;
}

#li_placement .SuggestedProduct .hotspotbuy .WishIcon,
#li_placement .hotspotbuy .WishIcon {
    background-position: -23px -1012px;
}

#li_placement .hotspotbuy .SuggestedProduct .WishIcon:hover,
#li_placement .hotspotbuy .WishIcon:hover {
    background-position: -67px -1012px;
}

.PSPlacementHorizontal,
.PSPlacementVertical {
    margin-top: 20px;
}

#li_placement .PSPlacementVertical {
    margin-top: 0;
}

#li_placement .PSPlacementVertical .swiper-wrapper {
    width: auto;
}

.Home .PSPlacementHorizontal .PSPlacementTitle {
    padding: 10px;
    color: var(--site-black);
    font-size: 1.6em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    border: none;
}

#li_placement .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy {
    display: none !important;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li {
    width: 25%;
    text-align: center;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct h4 {
    padding: 0;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a {
    padding-bottom: 5px;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a .PSProdBrand {
    font-weight: bold;
}

#li_placement .PSPlacementVertical .ProductSuggestionsListing li {
    display: block;
    padding: 5px;
    text-align: center;
    width: 100%;
}

#li_placement .ProductSuggestionsListing > li > div > a:hover {
    text-decoration: underline;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct > a {
    display: inline-block;
    width: 100%;
}

#li_placement .PSPlacementVertical .SuggestedProduct > a {
    display: none;
}

.Home .PSPlacementHorizontal .PSSellPrice {
    font-size: 1.8em;
    font-weight: bold;
    line-height: 1.2em;
    color: var(--site-black);
}

.Home .PSPlacementHorizontal .PSSellPrice.productHasRef {
    color: #ee3123;
}

.Home .PSPlacementHorizontal .PSProdPrice {
    padding: 0 5px;
}

.Home .PSPlacementHorizontal .PSProdPrice .PSRefPrice {
    font-size: 1.2em;
    text-decoration: line-through;
}

#li_placement .BlockDiv {
    display: block;
    clear: both;
}

#li_placement .productimage.s-productthumbimage {
    position: relative;
}

#li_placement .PSPlacementVertical .productimage {
    padding: 0 25%;
    margin-bottom: 5px;
}

#li_placement .QuickBuyButtonContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
}

.SuggestedProduct > h4 a:hover div {
    text-decoration: underline;
}

.appLinks,
.appLinks div {
    background-color: #1c1c1c;
    text-align: center;
}

.appLinks .linkContainer {
    display: flex;
    border-bottom: 0.5px solid #414141;
}

.appLinks .appText {
    color: var(--site-white);
    font-size: 16px;
    background-color: #1c1c1c;
    padding: 28.5px 43px 0;
    text-align: center;
}

.appLinks .copyrightText {
    font-size: 12px;
    color: #7c7c7c;
    margin: 0;
}

.appLinks a {
    display: flex;
}

.appLinks .mobMenuNewsletter .linkContainer {
    border-bottom: 0.5px solid #414141;
    background-color: #1c1c1c;
}

.appLinks::after {
    content: "";
    border: 0;
    border-top: 4px solid #0000ed;
    border-image-source: linear-gradient(to left, #ee3424 50%, #0000ed 50%);
    border-image-slice: 1;
    left: 0;
    position: absolute;
    height: 1px;
    width: 100%;
}

.mobMenuNewsletter .linkContainer {
    display: flex;
}

.downloadContainer {
    padding-bottom: 10px;
}

.storeLinks {
    display: flex;
}

.appImageApple a,
.appImageGoogle a {
    padding: 20px 5px !important;
}

.appImageApple a {
    justify-content: flex-end;
}

.appImageApple,
.appImageGoogle {
    width: 50%;
    padding: 0 5px;
}

.appImageApple .appleImage,
.appImageGoogle .googleImage {
    height: 36px;
}

.copyrightContainer {
    padding: 15px 0;
    border-top: 0.5px solid #414141;
}

@media (max-width: 320px) {
    .appImageApple span,
    .appImageGoogle span {
        background-size: 750px 900px;
    }

    .appImageApple span {
        background-position: 5px -469px;
        max-width: 145px;
    }

    .appImageGoogle span {
        background-position: -145px -469px;
        max-width: 145px;
    }
}

@media (min-width: 768px) {
    .Home .PSPlacementHorizontal .ProductSuggestionsListing {
        flex-flow: row wrap;
        max-width: 1440px;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        padding: 20px 30px;
    }

    .Home .PSPlacementHorizontal .productimage .PSImage .PSPimg-res {
        width: 70%;
        padding-bottom: 15px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        width: 33.333333% !important;
        max-width: none;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(6),
    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(7) {
        display: none;
    }
}

@media (max-width: 767px) {
    .Home .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy,
    .li-pr-basket .PSPlacementHorizontal .ProductSuggestionsListing .hotspotbuy {
        display: none !important;
    }

    .Home #li_placement .productimage.s-productthumbimage {
        padding: 0 50px;
    }
}

#lblCategoryCopy .textLeft p #catCopyMobileReadMoreLess,
.topheadbox .textLeft p #catCopyMobileReadMoreLess {
    cursor: pointer;
    font-weight: bold;
}

.Browse .searchWrapResults {
    font-size: 1.2em;
}

#lblCategoryCopy .catTemplate .textLeft {
    padding: 0 20px;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink {
    display: block;
    text-decoration: underline;
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    color: #636363;
}

#lblCategoryCopy .catTemplate .siblingMore {
    margin-top: 10px;
    font-weight: bold;
    display: block;
    letter-spacing: 0.1em;
}

#lblCategoryCopy .catTemplate .textLeft p {
    display: block !important;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink,
#lblCategoryCopy .catTemplate .siblingMore {
    font-size: 1em;
}

.mobImg::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 45%;
    height: 100%;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 12px;
}

@media (min-width: 768px) {
    .mobImg {
        display: none;
    }
}

.adidasBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-adidas.svg");
}

.asicsBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-asics-white.svg");
}

.KarrimorBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-karrimor.svg");
    width: 35%;
}

.NBBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-nb.svg");
}

.nikeBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-nike.svg");
}

.SlazengerBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-slazenger.svg");
}

.UABrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-ua.svg");
}

.usapBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-usapro.svg");
    width: 55%;
}

/*Reviews*/
#productDetails .rating-container {
    margin-bottom: 0 !important;
    min-height: 19px;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container {
    margin: 0 !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_averageRating_component_container {
    display: none !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_component_container {
    padding-top: 1px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    padding-right: 8px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 5px !important;
}

.ProdDetails div.bv_modal_component_container[data-bv-modal] {
    z-index: 6000 !important;
}

.reviews-container [data-bv-show="inline_rating"] > div {
    display: block !important;
}

.reviews-container [data-bv-show="reviews"],
.reviews-container [data-bv-show="questions"] {
    padding: 20px 0 !important;
}

.bv_modal_component_container .bv_histogram_row_container {
    height: auto !important;
    background-color: #f7f7f7 !important;
    padding: 8px !important;
    display: block !important;
}

.bv_modal_component_container .bv_histogram_row_container:hover {
    background-color: #efefef !important;
}

.bv_modal_component_container .bv_button_component_container {
    padding-top: 15px !important;
}

.bv_modal_component_container .bv_histogram_row_star {
    padding-left: 3px !important;
}

.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    width: 100% !important;
    padding: 15px !important;
    transition: background-color 0.3s ease-in;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-wide .bv-mbox {
    background-color: #f0f0f0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate
    .bv-mbox-fullprofile
    .bv-sidebar
    .bv-author-profile-userinfo
    .bv-author-userstats-list
    .bv-author-userstats-value {
    background-color: rgba(25, 25, 25, 0.1) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more {
    text-transform: uppercase !important;
    background-color: #0000ed !important;
    color: var(--site-white) !important;
    padding: 10px !important;
    border: 0 !important;
    width: auto !important;
    min-width: 200px !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover {
    background-color: #0000da !important;
    color: var(--site-white) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    span::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 0;
    right: 100%;
    background-color: var(--site-white);
    transition: right 0.25s ease-out 0s;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover
    span::before {
    right: 0;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    .bv-content-btn-pages-load-more-text {
    text-transform: uppercase !important;
    cursor: pointer !important;
    position: relative !important;
}

.reviews-container .bv-cv2-cleanslate .bv-content-header .bv-content-title-container {
    padding-top: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-content-title {
    padding-top: 25px !important;
}

.reviews-container .bv-cv2-cleanslate {
    margin: 20px 0;
}

@media (max-width: 767px) {
    .reviews-container [data-bv-show="reviews"],
    .reviews-container [data-bv-show="questions"] {
        padding: 20px 15px !important;
    }

    .reviews-container .bv-cv2-cleanslate {
        margin: 20px 10px;
    }

    #productDetails .rating-container {
        padding: 0 15px;
    }
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin: 10px 5px 10px 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-bottom: 30px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-noreviews-label,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    vertical-align: middle !important;
    line-height: 20px !important;
    margin: 10px 5px 10px 10px !important;
    display: inline-block !important;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1.2em !important;
    font-size: 1.2em !important;
    padding: 0 !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel1,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel2 {
    display: inline-block !important;
    float: none !important;
}

.reviews-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    line-height: 15px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    display: none !important;
}

.sdDetails .stockLevelWrapper {
    width: 100%;
}

/* SD LV8 */
/* Global overrides*/
.s-breadcrumbs-bar a:hover,
.s-breadcrumbs-bar a:focus {
    color: var(--site-black);
}

.s-breadcrumbs-bar a:first-of-type {
    padding-left: 5px;
}

.s-breadcrumbs-bar li:first-of-type a {
    padding-left: 0;
}

.s-breadcrumbs-bar .hereTItle {
    display: none;
}

.sdDetails .ProdDetLeft .logontitle {
    padding-bottom: 0;
}

.brandTitle-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.brandTitle a,
.brandTitle a span {
    color: #7d8082;
}

#lblFromText {
    text-transform: lowercase;
}

.sdDetails .AltProdDet .title h1 #lblProductName,
.sdDetails .AltProdDet .pdpPrice,
.sdDetails .AltProdDet .originalprice {
    letter-spacing: normal;
}

.sdDetails .AltProdDet .pdpPrice {
    font-size: 1.4em;
}

.sdDetails .AltProdDet .originalprice {
    padding-right: 3px;
    padding-left: 0;
    color: #7d8082;
}

.sdDetails .AltProdDet .spnFrom {
    margin-right: 3px;
}

.sdDetails .frasers-plus-payment-enabled .FrasersPlusContainer {
    margin-top: 16px;
}

.sdDetails .frasers-plus-payment-enabled .productNextDayDeliveryCountdownWrapper + .FrasersPlusContainer {
    margin-top: 0;
}

.swapColour .s-productextras-column-2-3 {
    padding: 5px 0 5px 5px;
}
/* Reviews */
#productDetails .rating-container div.bv_main_container_row_flex {
    display: none !important;
}

#productDetails .rating-container .bv_numReviews_text {
    color: #0000ed !important;
    background-color: #e7eaec !important;
    border-radius: 8px !important;
    padding: 3.5px !important;
    font-size: 10px !important;
    text-decoration: none !important;
}

/* PDP Elevation 21 */
#productImages #ImagesLoadingPlaceHolderWrap::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

#productImages #ImagesLoadingPlaceHolder {
    top: 0;
}

.sdDetails #productImages #productImageContainer {
    width: 100%;
}

#productImages #productImageContainer .productImage.productImageGrid {
    position: relative;
}

#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer,
.BasketWishContainer {
    display: flex;
}

#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
    display: none;
}

.BasketWishContainer {
    margin-top: 20px;
    margin-bottom: 14px;
    flex-wrap: wrap;
}

.BasketWishContainer .addtoBagWrap {
    flex: 1;
}

.s-basket-quantity-text-box,
.s-basket-quantity-text-box input {
    height: 100%;
}

.s-basket-quantity-text-box input {
    border: none;
    padding: 0;
    margin: 0;

    &:focus,
    &:hover {
        border: none;
    }
}
/* Chrome, Safari, Edge, Opera */
.s-basket-quantity-text-box input::-webkit-outer-spin-button,
.s-basket-quantity-text-box input::-webkit-inner-spin-button {
    margin: 0;
}

@media (min-width: 1022px) {
    .mp-container .mp-menu {
        display: none;
    }
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        flex: 0 0 calc(50% - 5px);
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer {
        justify-content: center;
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer a {
        flex: 0 0 calc(68% - 5px);
    }

    .SzQuantGroup .sizeButtons li {
        width: 25%;
    }

    /* three sizes */
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3),
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%;
    }
}

/* 360 view */
.sdDetails #Popup360ButtonWrapper {
    position: absolute;
    left: 18px;
    bottom: 18px;
    width: 46px;
    border-radius: 100%;
    border: 1px solid #5d5c5c;
    background-color: var(--site-white);
}

.sdDetails #Popup360ButtonWrapper::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.sdDetails #Popup360ButtonWrapper .glyphicon {
    display: none;
}

.sdDetails #Popup360ButtonWrapper #Popup360Label {
    position: absolute;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sdDetails .swiper-slide.video-slide:hover .vjs-play-control,
.sdDetails .swiper-slide.video-slide:focus .vjs-play-control,
.sdDetails .swiper-slide:hover .popup360Button,
.sdDetails .swiper-slide:focus .popup360Button {
    color: var(--site-black) !important;
    border: 1px solid var(--site-black) !important;
}

.productRollOverPanel .pinch-zoom-container {
    height: 100% !important;
}

.productRollOverPanel .pinch-zoom-container::before,
#productImages #productImageGrid .zoomMainImage::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.productRollOverPanel .pinch-zoom-container .imgProduct,
.productRollOverPanel .pinch-zoom-container,
#productImages #productImageGrid .zoomMainImage .imgProduct {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    aspect-ratio: 1/1;
}

.ImageNotReady #productImageGrid .productRollOverPanel {
    display: none;
}

@media (min-width: 1022px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a,
    .swiper-slide.video-slide {
        flex: 0 0 calc(50% - 5px);
    }
}

@media (min-width: 768px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        margin-bottom: 10px;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        cursor: pointer;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5em;
        color: var(--site-black);
        z-index: 1;
    }

    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .innerImageContainer
        a.viewMoreNumber
        .viewMoreImageGrid {
        flex: 0 0 50%;
        opacity: 0.3;
        background-size: cover;
    }

    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .innerImageContainer
        a.viewMoreNumber
        .viewMoreImageGrid::before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer .viewMoreHide {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
        flex: 0 0 100%;
    }
}

@media (max-width: 767px) {
    #productImages .ThumbProdWrap {
        padding: 0;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar {
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 150px;
        height: 3px;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar .swiper-scrollbar-drag {
        background-color: #000;
    }
}

/* Elevation PDP - move up when Grid branch merged */
.sdDetails .AltProdDet .infoTabs {
    display: none;
}

.sdDetails .infoaccordion {
    display: block;
}

.sdDetails .myIdPersonalisationContainer {
    background: transparent;
}

.sdDetails .myIdPersonalisationWrap,
.productPersonalisation .PersonaliseSpace {
    margin-bottom: 10px;
    padding: 0;
    background: var(--site-white);
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap a svg,
.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a svg,
.AltProdDet #productDetails .PersVouchBasketContainer a svg {
    display: none;
}

.sdDetails .AltProdDet .addToBasketContainer.sizeSelected .ImgButWrap #aPrePersAddToBag,
#divPersaddToBasketContainer .ImgButWrap a {
    background-color: #80e096 !important;
    color: var(--site-black) !important;
    border-radius: 4px;
}

.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a span,
.AltProdDet #productDetails .PersVouchBasketContainer a span,
#productMyIdPerosonalisationPages .addtoBagWrap a span,
.sticky-atb .sticky-atb--buysection a span {
    position: relative;
    z-index: 1;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderbuttons .preorderContinueButton,
#productMyIdPerosonalisationPages .addtoBagWrap.sizeSelected a {
    background-color: var(--site-black) !important;
    color: var(--site-white) !important;
    opacity: 1;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .sizeSelected a::after,
.AltProdDet #productDetails .PersVouchBasketContainer a::after,
#productMyIdPerosonalisationPages .addtoBagWrap a::after,
.sticky-atb .sticky-atb--buysection .sizeSelected a::after {
    display: block;
    content: "";
    position: absolute;
    border-radius: 25px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 85%;
    transition: right 0.7s ease-in;
}

.sticky-atb .sticky-atb--buysection .sizeSelected a::after {
    right: 70%;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addToBagInProgress a::after,
.AltProdDet #productDetails .PersVouchBasketContainer.addToBagInProgress a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addToBagInProgress a::after,
.sticky-atb .sticky-atb--buysection .addToBagInProgress a::after {
    background-color: var(--amara-pale-green);
    right: 25%;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addedToBag a::after,
.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::after,
.sticky-atb .sticky-atb--buysection .addedToBag a::after {
    right: 0;
    border-radius: 4px;
    opacity: 0;
    background-color: var(--site-green);
}

.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::before,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::before,
.sticky-atb .sticky-atb--buysection .addedToBag a::before {
    display: inline-block;
    margin-right: 10px;
    content: "";
    height: 14px;
    width: 7px;
    border-right: 2px solid var(--site-white);
    border-bottom: 2px solid var(--site-white);
    transform: rotate(45deg);
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addedToBag a::before {
    display: inline-block;
    content: "";
    transform: none;
    height: 14px;
    width: auto;
    border: 0;
    margin-right: 0;
}

#productDetails .TrafficLights .stockRed,
#productDetails .TrafficLights .stockYellow {
    text-decoration: none;
    text-align: center;
    float: none;
    margin: 10px 0 0;
    font-size: 0.75rem;
    font-weight: 400;
    color: #ed0000;
}

#productDetails .TrafficLights .stockRed::before,
#productDetails .TrafficLights .stockYellow::before {
    content: "!";
    border-radius: 50%;
    font-weight: 700;
    color: var(--site-white);
    margin-right: 8px;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-color: #ed0000;
}

#productDetails .TrafficLights .stockGreen {
    display: none !important;
}

.ndd-countdown .countdown-timer .countdown-timer-wrapper {
    background-color: transparent;
    color: var(--site-black);
    width: 100%;
}

.ndd-countdown .countdown-timer .countdown-timer-wrapper .ndd-countdown-time-unit {
    margin-right: 5px;
    font-weight: 700;
}

.ndd-countdown .countdown-timer-minute-container,
.ndd-countdown .countdown-timer-second-container {
    margin-left: 0;
}

.inline-countdown .ndd-countdown .countdown {
    display: inline-block;
}

.inline-countdown .ndd-countdown .countdown-timer-hour-container,
.inline-countdown .ndd-countdown .countdown-timer-minute-container {
    color: #0000ed;
    display: flex;
    transform: translateY(1px);
}

.inline-countdown .ndd-countdown .countdown-timer.cut-off .countdown-timer-hour-container,
.inline-countdown .ndd-countdown .countdown-timer.cut-off .countdown-timer-minute-container {
    color: #ed0000;
}

.inline-countdown .ndd-countdown .countdown-timer .countdown-timer-wrapper {
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.inline-countdown .ndd-countdown .ndd-countdown-time-unit {
    margin-right: 5px;
}

.inline-countdown .ndd-countdown .ndd-countdown-time-hour,
.inline-countdown .ndd-countdown .ndd-countdown-time-minute,
.inline-countdown .ndd-countdown .ndd-countdown-time-second {
    min-width: 15px;
    font-weight: 200;
}

.inline-countdown .ndd-countdown .countdown-timer .countdown-timer-wrapper .ndd-countdown-time-unit {
    font-weight: 200;
    margin-left: 0;
}

/* Online Prices Differ note */
.ProdDetails .online-prices-differ-note {
    margin-bottom: 14px;
}

#productDetails .recognitionStrip {
    margin-bottom: 15px;
}

/* accordions */
.infoaccordions div:first-child .panel-heading {
    border-top: 1px solid #dde1e4;
}

.infoaccordions {
    margin-top: 10px;
}

.shippingaccordion .panel-heading {
    border-bottom: 1px solid #dde1e4;
    padding: 0;
    border-radius: 0;
}

.shippingaccordion .panel-heading {
    border-bottom: 1px solid #dde1e4;
}

.infoaccordions .panel-body {
    background-color: var(--site-white);
    border-bottom: 1px solid #dde1e4;
}

.infoaccordions .DeliveryMethodsContainer {
    margin-bottom: 16px;
}

.infoaccordions .DeliveryMethods li,
.infoaccordions .DeliveryMethods li > div:first-child,
#DisplayAttributes li {
    display: flex;
}

.infoaccordions .DeliveryMethods li > div:first-child {
    padding-right: 16px;
    flex: 1;
    flex-direction: column;
}

.infoaccordions .DeliveryMethods li .DeliveryMethodDescription,
.infoaccordions .DeliveryMethods li .SpendVoucher {
    margin: 0;
    flex: 1;
}

#DisplayAttributes {
    margin: 0;
    display: block;
}

#DisplayAttributes li {
    padding-bottom: 8px;
    list-style-type: none;
    border: none;
}

#DisplayAttributes li:last-child {
    padding-bottom: 0;
}

#DisplayAttributes li span.feature-name,
#DisplayAttributes li span.feature-value {
    width: auto;
    padding: 0;
}

#DisplayAttributes li span.feature-name {
    padding-right: 8px;
}

#DisplayAttributes li span.feature-name::after {
    content: ": ";
    display: inline-block;
    padding-left: 2px;
}

#DisplayAttributes li span.feature-value {
    flex: 1;
}

@media (min-width: 769px) {
    .productImage {
        max-width: 100%;
    }
}

.sdDetails #pnlRecentlyViewedProducts {
    padding: 20px 15px;
}

#pnlRecentlyViewedProducts .swiper-slide {
    padding-right: calc(var(--padding-unit) * 2);
}

/* Pre-Order Modal Styling */

#PreorderPopupModal .modal-dialog.popupPreorderPopup {
    padding: 0;
    position: fixed;
    bottom: 0;
    height: min-content;
}

#PreorderPopupModal .popupPreorderPopup .modal-body {
    padding: 0 16px 30px;
    height: 370px;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header .text-center,
#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent,
.Checkout22 .OrderCompleteRegistrationDone p {
    text-align: left;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header .header-text {
    text-transform: initial;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header {
    padding-left: 16px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preOrderText {
    font-size: 0.938rem;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preorderDate {
    display: block;
    margin: 15px 0;
    font-size: 1.2rem;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap {
    max-width: 100%;
    padding: 0;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap a {
    border-radius: 4px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child {
    margin-bottom: 12px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child a {
    background-color: #80e096 !important;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child a span {
    color: #131313;
}

@media (min-width: 500px) {
    #PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child {
        padding-right: 10px;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:last-child {
        padding-left: 10px;
    }
}

@media (min-width: 768px) {
    #PreorderPopupModal .modal-dialog.popupPreorderPopup {
        bottom: unset;
        left: 0;
        right: 0;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body {
        padding: 7px 40px 23px;
        height: fit-content;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent {
        text-align: center;
        margin-bottom: 15px;
        justify-content: center;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preOrderText {
        margin-top: 23px;
    }
}

.sticky-atb .sticky-atb-img {
    background-color: var(--site-white);
}

/* PDP Container Changes */
.pdpExtras {
    background: var(--site-white);
    padding-top: 40px;
    padding-bottom: 40px;
}

.sdDetails .ResponsiveProductDetail,
.sdDetails .PSPlacementWrapper {
    background: var(--site-white);
}

.sdDetails .BreadcrumbGroupWrapper {
    padding-top: 10px;
}

.sdDetails .prodDetailsWrap.col-xs-12,
.sdDetails .pdpMoreLinksDelivery.col-xs-12 {
    padding: 0;
}

.sdDetails .shippingaccordion .panel-heading a {
    padding: calc(var(--padding-unit) * 4.5) calc(var(--padding-unit) * 4) calc(var(--padding-unit) * 4.5) 0;
}

.sdDetails .PSPlacementWrapper {
    padding-left: 15px;
    padding-right: 15px;
}

.OrderComplete.Checkout22 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1600px;
    margin: 0 auto;
}

.orderCompleteRegisterShown {
    display: flex;
    flex-wrap: wrap;
}

.orderCompleteRegisterShown .orderInformationWrapper {
    order: 1;
}

.orderCompleteRegisterShown .order-summary {
    order: 2;
}

.orderCompleteRegisterShown .RegisterWrapper,
.orderCompleteRegisterShown .OrderCompleteRegistration {
    order: 4;
}

.orderCompleteRegisterShown .footer-nav {
    order: 3;
}

.OrderComplete.Checkout22 h1,
.order-complete-subtitle {
    text-align: left;
    margin-bottom: 30px;
    font-style: italic;
    font-weight: 400;
}

.order-details-delivery-date span {
    color: #0000ed;
}

.OrderComplete.Checkout22 h1 {
    text-transform: uppercase;
}

.OrderComplete.Checkout22 h1 em {
    font-style: inherit;
}

.OrderComplete.Checkout22 h1 em::before {
    display: none;
}

.order-complete-messages .subtitle,
.order-complete-subtitle {
    margin-bottom: 10px;
}

.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    font-style: italic;
    border: 1px solid;
    display: block;
    line-height: 1em;
    letter-spacing: 1.4px;
}

.OrderComplete .order-complete-messages a.button {
    margin-top: 20px;
}

.order-summary-discount-value {
    color: #ed0202;
}

.sdDetails .hereTItle {
    display: none;
}

@media (max-width: 425px) {
    .sdDetails .colourImages li {
        width: 70px;
        height: 70px;
    }
}

@media (min-width: 1640px) {
    .sdDetails .ContentWrapper {
        max-width: none;
    }

    .sdDetails .productImageContentWrapper,
    .sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
    .sdDetails .BreadcrumbGroupWrapper {
        background: transparent;
    }
}

@media (min-width: 1022px) {
    .sdDetails .sdPageProductContainer {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .sdDetails .reviews-container,
    .OrderComplete.Checkout22 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .order-complete-messages {
        margin-bottom: 50px;
    }

    .order-complete-subtitle {
        margin-bottom: 30px;
    }

    .OrderComplete .order-complete-messages a.button {
        margin-top: 40px;
    }

    .sticky-atb .originalprice {
        padding-right: 0;
    }
}

/* 360 Modal styling for elevation only */
.popupFrame360.popupFullscreen .modal-content .sliderWrap {
    bottom: 40px;
}

.popupFrame360.popupFullscreen .modal-content .sliderWrap input[type="range"] {
    margin: 0 auto;
    padding: 0 20px;
}

.popupFrame360.popupFullscreen input[type="range"]::-webkit-slider-runnable-track {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-webkit-slider-thumb {
    border: none;
    background: #0000ed;
    transition: transform 0.3s;
}

.popupFrame360.popupFullscreen input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.1);
}

.popupFrame360.popupFullscreen input[type="range"]::-moz-range-track {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-moz-range-thumb {
    border: none;
    background: #0000ed;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-fill-lower {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-fill-upper {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-thumb {
    border: none;
    background: #0000ed;
}

.ProdDetails .ShopTheLook {
    background-color: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 24px 0;
    border: none;
}

.ProdDetails .ShopTheLook::before,
.ProdDetails .ShopTheLook::after {
    display: none;
}

.ProdDetails .ShopTheLook .shopLookImg {
    overflow: hidden;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ProdDetails .ShopTheLook .shopLookImg h2 {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    color: var(--site-white);
    margin: 0;
    border: none;
}

.ProdDetails .ShopTheLook .shopLookImg img {
    width: 100%;
    display: none;
}

.ProdDetails .ShopTheLook .shopLookCarousel {
    padding: 0 0 40px;
    display: flex;
    align-items: center;
    max-width: none;
}

.ProdDetails .ShopTheLook .shopLookCarousel > div {
    width: 100%;
}

.ProdDetails .ShopTheLook .LookProducts li {
    margin-top: 16px;
    min-height: auto;
    border: none;
}

.ProdDetails .ShopTheLook .productlist-overlay,
#BodyWrap .ShopTheLook .hotspotbuy.hotspotquickbuy,
.ShopTheLook .hotspotbuy.hotspotsaveforlater {
    display: none !important;
}

.ProdDetails .ShopTheLook .s-productthumbbox {
    display: block;
}

.ProdDetails .ShopTheLook .s-productthumbtext {
    background-color: transparent;
}

.ProdDetails .ShopTheLook .CurrencySizeLarge.curprice {
    padding: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook,
.ProdDetails .ShopTheLook .swiper-button-nextLook {
    top: 50%;
    left: 0;
    background-color: var(--site-white);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.ProdDetails .ShopTheLook .swiper-container.swiper-container-shop-the-look:not(.swiper-grid) {
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.ProdDetails
    .ShopTheLook
    .swiper-container-shop-the-look:not(.swiper-grid)
    ul#navlist.s-productscontainer2.swiper-wrapper {
    padding: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook:hover,
.ProdDetails .ShopTheLook .swiper-button-nextLook:hover {
    opacity: 1;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook.swiper-button-disabled,
.ProdDetails .ShopTheLook .swiper-button-nextLook.swiper-button-disabled {
    opacity: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook svg {
    transform: rotate(180deg);
}

.ProdDetails .ShopTheLook .swiper-button-nextLook {
    right: 0;
    left: auto;
}

.swiper-scrollbar-shopLook.swiper-scrollbar-horizontal {
    position: relative;
    left: 50%;
    bottom: 3px;
    height: 3px;
    width: 30%;
    background-color: #d3d3d3;
    transform: translateX(-50%);
    padding: 1px 0;
    transition:
        all 0.25s ease-in,
        transform 0s;
    margin: 32px 0 0;
}

.swiper-scrollbar-shopLook .swiper-scrollbar-drag {
    background: #0000ed;
    cursor: pointer;
    height: 4px;
    margin: -2px 0;
    transition:
        all 0.25s ease-in,
        transform 0s;
}

@media screen and (max-width: 767px) {
    .ProdDetails .ShopTheLook .shopLookImg {
        padding: 0;
        height: 100vw;
    }

    .ProdDetails .ShopTheLook .shopLookImg img {
        display: none;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 768px) {
    .ProdDetails .ShopTheLook {
        padding-left: 40px;
        background-color: transparent;
    }

    .ProdDetails .ShopTheLook .swiper-container-shop-the-look:not(.swiper-grid)::after,
    .ProdDetails .ShopTheLook .swiper-container-shop-the-look:not(.swiper-grid)::before {
        content: "";
        display: block;
        opacity: 0.5;
        background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 42%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 45px;
        z-index: 10;
    }

    .ProdDetails .ShopTheLook .swiper-container-shop-the-look:not(.swiper-grid)::before {
        right: auto;
        left: 0;
        width: 30px;
        background: linear-gradient(270deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 42%);
    }

    .ProdDetails .ShopTheLook .swiper-navigation-disabled.swiper-container-shop-the-look:not(.swiper-grid)::before,
    .ProdDetails .ShopTheLook .swiper-navigation-disabled.swiper-container-shop-the-look:not(.swiper-grid)::after {
        display: none;
    }

    .ProdDetails .ShopTheLook .shopLookCarousel {
        padding: 18px 0 15px;
    }

    .swiper-scrollbar-shopLook .swiper-scrollbar-drag {
        height: 3px;
        margin: -1px 0;
    }
}

@media (min-width: 1022px) {
    .ProdDetails .ShopTheLook {
        padding-left: 60px;
    }

    .ProdDetails .ShopTheLook .shopLookCarousel {
        padding: 56px 0 21px;
    }

    .swiper-scrollbar.swiper-scrollbar-shopLook:focus .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook:hover .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook .swiper-scrollbar-drag:hover .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook .swiper-scrollbar-drag:active {
        height: 5px;
        margin: -2px 0;
    }

    .ProdDetails .ShopTheLook .swiper-container-shop-the-look:not(.swiper-grid)::before {
        width: 45px;
    }
}

/* BV search icon */

.reviews-container .bv-cv2-cleanslate .bv-content-search .bv-content-search-btn {
    display: flex !important;
    justify-content: center;
}

.reviews-container .bv-cv2-cleanslate .bv-content-search .bv-search-control-icon {
    font-size: 0 !important;
    background-position: -305px -385px !important;
    background-size: 766px 798px;
    width: 20px !important;
    height: 20px !important;
}

@media (min-width: 768px) {
    .reviews-container .bv-cv2-cleanslate .bv-content-search .bv-search-control-icon {
        background-position: -312px -339px !important;
        background-size: 800px 800px;
        position: relative !important;
        width: 30px !important;
        height: 30px !important;
    }
}

@media (min-width: 300px) {
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-content-search
        .bv-masthead-search
        .bv-content-search-btn
        .bv-search-control-icon,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-generic-submission
        .bv-masthead-search
        .bv-content-search-btn
        .bv-search-control-icon,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-content-search
        .bv-generic-submission
        .bv-content-search-btn
        .bv-search-control-icon,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-generic-submission
        .bv-generic-submission
        .bv-content-search-btn
        .bv-search-control-icon,
    .reviews-container .bv-cv2-cleanslate .bv-content-search .bv-search-control-icon {
        text-indent: 110% !important;
        overflow: hidden !important;
    }

    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-content-search
        .bv-masthead-search
        .bv-content-search-btn,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-generic-submission
        .bv-masthead-search
        .bv-content-search-btn,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-content-search
        .bv-generic-submission
        .bv-content-search-btn,
    .reviews-container
        .bv-cv2-cleanslate
        .bv-core-container-88
        .bv-generic-submission
        .bv-generic-submission
        .bv-content-search-btn {
        float: left !important;
        width: 35px !important;
        overflow: hidden !important;
    }
}

/* Hover/focus PDP */
.s-breadcrumbs-bar > span ol li a:hover,
.s-breadcrumbs-bar > span ol li a:focus {
    text-decoration: none;
}

.easyzoom-flyout {
    overflow: visible;
}

.popupFullscreen .ZoomBody ul li {
    overflow: hidden;
}

.sdDetails #MoreFromLinks li.MoreFromLinksRow a {
    color: var(--site-black);
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg::before,
.ShopTheLook.ShopTheLook--overlay-light .shopLookImg::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg::before {
    background: rgba(0, 0, 0, 0.3);
}

.ShopTheLook.ShopTheLook--overlay-light .shopLookImg::before {
    background: rgba(255, 255, 255, 0.3);
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg .LookHeader {
    color: var(--site-white);
}

.ShopTheLook.ShopTheLook--overlay-light .shopLookImg .LookHeader {
    color: var(--site-black);
}

/*Personalisation Wishlist*/
.PersonaliseSpace,
.myIdPersonalisationWrap {
    flex-basis: 100%;
}

#divPersaddToBasketContainer,
#pnlMyIdPersonalisation .addToBasketContainer {
    flex-basis: calc(100% - 48px);
}

#PersonalisationModal #divPersaddToBasketContainer,
#PersonalisationModal #pnlMyIdPersonalisation .addToBasketContainer {
    flex-basis: 100%;
}

#pnlMyIdPersonalisation .myIdTabBody {
    height: auto;
}

#aPersAddToWishList,
#aNoPersAddToWishList {
    cursor: pointer;
}

.modal-backdrop {
    display: none;
    opacity: 1;
    transition: none;
    background-color: transparent;
    transition-delay: 0s;
    filter: none;
}

.modal-backdrop.in {
    display: block;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
    transition-delay: 0s;
    filter: none;
    z-index: 12;
}

.sdDetails .PSPlacementWrapper.li-pr-pd.li-suggest {
    display: none;
}

.sdDetails .isPreOrderable {
    color: var(--site-white);
}

.sdDetails .colourImages.expandingColorways {
    display: block;
}

.sdDetails .colourImages.expandingColorways.expandColourways {
    height: auto;
}

.sdDetails .colourImages.expandingColorways.expandColourways li.colorwayExpandAction {
    display: none;
}

.order-details-section,
.Checkout22 .OrderCompleteRegisteredSignUp,
.RegisterWrapper-inner,
.Checkout22 .newsletterCompletion,
.order-summary {
    background: var(--site-white);
    border: 1px solid #e5e5e5;
}

.Checkout22 .newsletterCompletion,
.Checkout22 .OrderCompleteRegisteredSignUp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.OrderCompleteRegistrationDone {
    border: none;
    padding: 0;
}

.Checkout22
    .OrderCompleteRegistrationDone:not(
        .OrderCompleteRegisteredSignUp,
        .newsletterCompletion .OrderCompleteRegistrationDone
    ) {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistrationDone p:first-of-type:not(.registration-benefits p) {
    display: block;
    margin-left: 50px;
    position: relative;
}

.Checkout22 .OrderCompleteRegistrationDone p .global-icon-tick {
    left: -50px !important;
    top: 0;
}

.Checkout22 .global-icon-tick {
    position: absolute;
}

.Checkout22 .global-icon-tick svg {
    width: 34px;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationDone p:not(.OrderComplete .registration-benefit p) {
    margin-bottom: 30px;
}

.OrderCompleteRegistrationDone .reward-message p:not(:last-child) {
    margin-bottom: 10px;
}

.OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
    text-align: center;
    margin-top: 30px;
}

.OrderComplete.Checkout22 .footer-nav p {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
        justify-content: center;
    }

    .sdDetails .colourImages.expandingColorways {
        max-height: 128px;
    }
}

.order-details-section {
    max-width: 710px;
}

.Checkout22 .OrderCompleteRegistration {
    max-width: none;
}

.orderCompleteRegisterShown .innerBorder {
    margin-top: 10px;
}

.Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
.Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
.order-summary {
    padding: 18px 18px 30px;
}

.Checkout22 .OrderCompleteRegistrationFormInner {
    max-width: 750px;
}

.Checkout22 .newsletterCompletion p {
    font-size: 1.45em;
    line-height: 1.6em;
}

.OrderCompleteRegistrationFormInner .dnnFormMessage {
    padding: 10px 0;
}

.OrderComplete.Checkout22 h2,
.Checkout22 .SignUpWrapper,
.Checkout22 .RegisterPrompt {
    text-align: left;
}

.OrderComplete.Checkout22 h2.account-info {
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper {
    margin-bottom: 10px;
    display: flex;
    font-size: 1.45em;
    line-height: 1.6em;
    font-weight: 400;
}

.Checkout22 .NewsletterFree,
.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    text-transform: uppercase;
    color: #0000ed;
}

.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    color: var(--site-black);
}

.Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    padding: 16px 55px;
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper input {
    width: 15px;
    height: 15px;
    margin-right: 11px;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn,
.RegisterWrapper-inner a.button {
    width: 100%;
}

.orderCompleteRegisterShown .OrderCompleteRegistration .ContinueOn {
    margin: 20px 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader {
    padding: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader h2 {
    text-decoration: underline;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon {
    display: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon::before {
    background: none !important;
}

.orderCompleteRegisterShown .OrderCompleteLogin .OrderCompleteLoginForm {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginPrompt,
.OrderCompleteRegisteredSignUp {
    text-align: left;
}

.OrderCompleteRegistrationForm .field {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationForm,
.orderCompleteRegisterShown .OrderCompleteLogin {
    border: none;
}

.orderCompleteRegisterShown .OrderCompleteLogin {
    margin-bottom: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginForm {
    padding: 10px 0 0;
}

.orderCompleteRegisterShown .OrderCompleteLogin .NewCustWrap {
    position: relative;
}

.orderCompleteRegisterShown .loginContainer .ForgotPass {
    padding: 10px 0;
    text-align: left;
}

.OrderComplete.Checkout22 .OrderCompleteLogin .ForgotPass .ForgotPasswordLinkButton {
    font-weight: 400;
}

.Checkout22 .OrderCompleteRegistrationDone p:not(.RegisterWrapper .OrderCompleteRegistrationDone p) {
    display: block;
    text-align: left;
    margin: 0 0 0 50px;
}

.Checkout22 .OrderCompleteRegistrationDone p a {
    color: var(--site-black);
}

@media (min-width: 768px) {
    .Checkout22 .OrderCompleteRegistration .ContinueOn,
    .RegisterWrapper-inner a.button {
        width: auto;
        min-width: 330px;
    }
}

@media (min-width: 1022px) {
    .orderCompleteRegisterShown .OrderCompleteLogin {
        padding-bottom: 20px;
    }

    .orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
        padding-bottom: 20px;
    }

    .orderCompleteRegisterShown .RegisterWrapper,
    .orderCompleteRegisterShown .OrderCompleteRegistration {
        order: 2;
    }

    .orderCompleteRegisterShown .order-summary {
        order: 3;
    }

    .orderCompleteRegisterShown .footer-nav {
        order: 4;
        margin-top: 30px;
    }

    .orderCompleteRegisterShown .orderInformationWrapper,
    .orderCompleteRegisterShown .RegisterWrapper {
        width: 50%;
    }

    .orderCompleteRegisterShown .RegisterWrapper {
        padding-top: 50px;
        padding-left: 50px;
    }

    .Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
        width: auto;
        display: inline-block;
    }

    .OrderComplete.Checkout22 .footer-nav p {
        margin-bottom: 0;
    }

    .Checkout22 .newsletterCompletion,
    .Checkout22 .OrderCompleteRegisteredSignUp {
        flex-direction: row;
    }

    .Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
    .Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
    .order-summary {
        padding: 22px 22px 30px;
    }

    .Checkout22 .OrderCompleteRegistrationDoneImage {
        width: 100%;
        max-width: 450px;
    }
}

.Checkout22 .OrderCompleteRegistrationDoneImage > div {
    height: 100%;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper ul li.Center > div.Center {
    display: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper {
    overflow: visible !important;
    height: auto;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.CycleCenter.Center {
    padding: 0;
    display: block !important;
    position: relative;
    max-width: none;
    width: 100%;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.CycleCenter.Center > ul {
    padding: 0;
    display: block !important;
    position: relative;
    max-width: none;
    width: 100%;
    min-height: 495px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.CycleCenter.Center > ul li > a {
    min-width: 0;
}

nav#topMenu ul li.root.sidebarSubMenu.multicolumn .SubMenuWrapper ul li.level1 > a:hover,
nav#topMenu ul li.root.sidebarSubMenu.multicolumn .SubMenuWrapper ul li.level1.activeItem > a {
    font-weight: var(--font-weight-bold);
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::before,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::after {
    position: absolute;
    display: block;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    border-left: 1px solid #ddd;
    width: 1px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::after {
    right: 0;
    left: auto;
}

nav#topMenu li.root.sidebarSubMenu .SubMenuWrapper li.Bottom {
    display: none !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center::before,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center::after {
    content: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul {
    border: 0;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center {
    padding: 0;
    display: block !important;
    position: relative;
    max-width: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul {
    width: 100%;
    left: 0;
    top: 70px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li ul li.mmHasChild.level1.sdmColHeader > ul.open {
    display: flex !important;
    top: 70px;
    left: 0;
    width: 100%;
    padding: 0;
    height: auto;
    justify-content: center;
    background-color: #fff;
    flex-wrap: wrap;
    border-top: 1px solid #ddd;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    padding: 20px 5px 10px 20px !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .Center > ul li.level1.sdmColHeader {
    height: auto;
    padding: 25px 20px 0 0;
    width: auto;
    position: static;
    border: 0;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Left {
    display: none !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.Left {
    width: 16.666667%;
    padding: 20px 5px 25px;
    max-width: 250px;
    float: none;
    order: 1;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fiveCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fiveCol li.Left {
    width: 20%;
    max-width: 300px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fourCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fourCol li.Left {
    width: 25%;
    max-width: 375px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .threeCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .threeCol li.Left {
    width: 33.333333%;
    max-width: 500px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .twoCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .twoCol li.Left {
    width: 50%;
    max-width: 750px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.Left {
    order: 2;
    padding: 0;
    display: inline-block !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Right {
    width: 100%;
    flex-basis: 100%;
    float: none;
    order: 3;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Right > .Right {
    display: block !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .Center > ul li.level1.sdmColHeader.allSportsMenu {
    padding-left: 40px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a {
    font-size: 1.2em !important;
    text-decoration: none;
    font-weight: var(--font-weight-regular);
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li .fourPromoSlot .fourPromoItem a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.promoEnabled .twoStackFlex .twoStackItem a::before {
    content: none;
}
