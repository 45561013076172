#hotspotModal {
    .modal-dialog {
        max-width: 1030px;
        background-color: var(--site-white);

        .modal-content {
            background-color: var(--site-white);
            border: none;
            outline: none;
        }

        @media (max-width: 767px) {
            margin-top: 0 !important;
            height: 100%;
            border-radius: 0;

            .modal-content {
                height: 100%;
                max-height: initial !important;
            }
        }
    }

    .modal-header {
        position: absolute;
        height: 49px;
        padding: 10px 44px;

        .close {
            background-image: var(--spritesheet) !important;
            background-size: 700px 700px !important;
            background-position: -412px -327px !important;
            height: 40px;
            width: 40px;
            right: 8px;
            top: 8px;
        }
    }

    .modal-body {
        padding: 0 0 52px;
        background-color: var(--site-lightest-grey);

        .row {
            display: flex;
            margin: 0;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
        }

        @media (max-width: 767px) {
            height: 100%;
            max-height: initial !important;
        }
    }

    .PinWrapImage {
        padding: 16px;
        max-width: 556px;
        width: 100%;

        @media (min-width: 768px) {
            display: flex;
            align-items: center;
            max-width: 50%;
        }

        @media (min-width: 1022px) {
            max-width: 556px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            background-color: rgba(255, 255, 255, 0.5);
        }

        .swiper-pagination {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 8px;
            bottom: 0;

            .swiper-pagination-bullet {
                height: 3px;
                width: 34px;
                margin: 0;
                border-radius: 0;
                background: var(--site-light-grey);
                opacity: 1;
            }

            .swiper-pagination-bullet:hover {
                background: var(--site-mid-grey);
            }

            .swiper-pagination-bullet-active {
                background: var(--site-black);
            }
        }
    }

    .PinWrapText {
        width: 100%;
        padding: 25px;

        .col-xs-12 {
            width: 100%;
            flex: 1 1 100%;
            padding: 0;
        }

        .hsProductName {
            flex-grow: 1;
            flex-basis: calc(100% - 90px);
            padding: 0;
            order: 1;

            h2 {
                margin: 0;
                padding: 0;
                font-size: var(--subtitle-font-size);
                font-weight: var(--font-weight-regular);
                color: var(--site-dark-grey);

                span {
                    display: block;
                    font-size: var(--body-font-size);
                    color: var(--site-black);
                }
            }
        }

        .hsColourDesc {
            order: 3;
        }

        .hsColourSelection {
            order: 4;
        }

        .BuySizeText {
            order: 5;
        }

        .sizeGuideLink {
            order: 6;
        }

        .hsSizeSelection {
            order: 7;
        }

        #hsPriceWrapper {
            padding: var(--padding-unit) 0 0;
            flex-basis: 100%;
            order: 2;
            display: flex;
            flex-wrap: wrap;

            #hsPrice,
            #hsRefPrice {
                display: block;
                text-align: left;
                padding: 0;
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
                line-height: 20px;
                margin-right: var(--padding-unit);
            }

            #hsPrice {
                color: var(--site-red);
            }

            #hsPrice:only-child,
            #hsRefPrice {
                color: var(--site-black);
            }
        }

        #hsAdditionalPriceLabel {
            display: inline-block;
            font-size: 1.1em;
            padding: 0 5px 0 0;
            line-height: 1.3em;
            float: none;
            color: var(--site-black);
        }

        .hsColourDesc,
        .BuySizeText,
        .sizeGuideLink {
            margin: 24px 0 4px;
            padding: 0 5px 0 0;
        }

        .hsColourDesc .ColourLabel,
        .hsSizeLabel {
            font-style: normal;
            font-size: var(--subtitle-font-size);
            font-weight: var(--font-weight-regular);
            letter-spacing: normal;
        }

        .hsColourDesc .ColourLabel::after,
        .hsSizeLabel::after {
            content: ":";
        }

        .hsColourDesc .ColourDesc {
            font-size: var(--subtitle-font-size);
            line-height: 1.2em;
            color: var(--site-dark-grey);
        }

        .hsVariantButtons {
            display: flex;
            flex-wrap: wrap;
            padding-right: 5px;

            li {
                margin: 5px 0 5px 5px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--subtitle-font-size);
                    line-height: 1em;
                    border-color: var(--site-mid-grey);
                    border-radius: 4px;
                    color: var(--site-black);
                    overflow: hidden;
                    padding: 0;
                }

                a:hover {
                    border-color: var(--site-dark-grey);
                    font-weight: var(--font-weight-bold);
                }

                &.hsVariantHighlight a {
                    border: 1px solid var(--site-black) !important;
                    font-weight: var(--font-weight-bold);
                }

                &.greyOut a {
                    border-color: var(--site-light-grey);
                    color: var(--site-mid-grey);
                }
            }
        }

        .hsColourSelection .hsVariantButtons,
        .hsSizeSelection .hsVariantButtons {
            padding: 0;
            margin: 0 -5px;
        }

        .BuySizeText,
        .sizeGuideLink {
            flex-basis: 50%;
        }

        .sizeGuide-text {
            text-decoration: underline;
        }

        #ulHsSizes li {
            flex-basis: calc(25% - 5px);
        }

        .hsVariantHighlight {
            &::before {
                content: none;
            }
        }

        #hsAddToBagContainer,
        #hsAddToWishListContainer {
            margin-top: 24px;

            a {
                font-size: var(--subtitle-font-size);
                font-weight: var(--font-weight-extra-bold);
                padding: 20px 5px;
                color: var(--site-cta-text) !important;
                background-color: var(--site-cta-background) !important;
                border: 1px solid var(--site-cta-background);
                border-radius: 25px;
                text-shadow: none;

                &:hover {
                    background-color: var(--site-cta-background-hover) !important;
                    border: 1px solid var(--site-cta-background-hover);
                    color: var(--site-cta-text-hover) !important;
                }
            }
        }

        #hsAddToBagContainer.sizeRequired,
        #hsAddToWishListContainer.sizeRequired {
            a {
                background-color: var(--site-black) !important;
                border: 1px solid var(--site-black) !important;
                color: var(--site-white) !important;
            }
        }

        .hsbottom {
            width: 100%;
            order: 8;
            position: static;
            padding: 0 0 10px;
        }

        #hsViewProduct {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 52px;
            width: 100%;

            a {
                display: block;
                background: var(--site-white);
                font-weight: var(--font-weight-bold);
                font-size: var(--body-font-size);
                width: 100%;
                padding: calc(var(--padding-unit) * 4) 0;
                border: 1px solid var(--site-black);
            }

            @media (max-width: 1021px) {
                position: static;
                display: block;
            }
        }

        @media (min-width: 768px) {
            padding: 16px 48px 48px 43px;
            display: flex;
            flex-wrap: wrap;
            width: 50%;
            position: static;
        }

        @media (max-width: 1021px) {
            width: 100%;
            padding: 25px;
        }

        @media (min-width: 1022px) {
            width: calc(100% - 556px);
        }
    }
}
