:root {
    /* Search autocomplete spacer */
    --autocomplete-top: 4px;
    /* Primary colours */
    --site-white: #fff;
    --site-black: #000;
    --site-dark-grey: #5e5e5e;
    --site-mid-grey: #c4c4c4;
    --site-light-grey: #e4e8eb;
    --site-lightest-grey: #f8f7fc;
    --site-outlet: #a63b00;
    /* Secondary colours */
    --site-accent: #0050ff;
    --site-accent-secondary: #0a2afd;
    --site-green: #86ffa1;
    --site-dark-green: #73ca87;
    --site-red: #ed0000;
    --padding-unit: 4px;
    --header-font-size: 18px;
    --body-font-size: 14px;
    --subtitle-font-size: 16px;
    --font-weight-regular: normal;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --large-container-max-width: 1600px;
    --default-container-max-width: 1400px;
    --spritesheet: url("/images/core/chai-icons.svg");
    --default-font-family: RedHatDisplay, arial, helvetica, sans-serif;
    --font-family-bold: var(--default-font-family);
    --default-letter-spacing: 0;
    --site-footer-border: var(--site-accent);
    --site-count-bubble: var(--site-accent);
    /* CTA styling */
    --site-cta-background: var(--site-accent);
    --site-cta-text: var(--site-white);
    --site-cta-background-hover: var(--site-accent-secondary);
    --site-cta-text-hover: var(--site-white);
    --site-cta-transactional-background: var(--site-cta-background);
    --site-cta-transactional-text: var(--site-cta-text);
    --site-cta-transactional-background-hover: var(--site-cta-background-hover);
    --site-cta-transactional-text-hover: var(--site-cta-text-hover);
    /* Elevated Cart Variables */
    --cart-font-family: var(--default-font-family);
    --cart-font-family-bold: var(--default-font-family);
    --cart-font-family-italic: var(--default-font-family);
    --cart-sticky-element-default-top-value: 142px;
    --cart-details-margin-top-value: 32px;
    --cart-heading-font-style: normal;
    --cart-button-background-color: var(--site-black);
    --cart-button-hover-color: var(--site-dark-grey);
    --cart-button-font-style: normal;
    --cart-button-font-weight: var(--font-weight-regular);
    --cart-border-radius: 0;
    --cart-button-transactional-background-color: var(--site-black);
    --cart-button-transactional-hover-background-color: var(--site-dark-grey);
    --cart-button-transactional-font-color: var(--site-white);
    --cart-total-font-weight: var(--font-weight-bold);
    --cart-error-font-color: var(--site-red);
    --cart-discount-font-color: var(--site-red);
    --cart-error-background-color: hsl(355.13deg 93.02% 98%);
    /* Wishlist Variables */
    --wishlist-summary-header-font-style: normal;
    --wishlist-header-font-style: normal;
    --product-line-item-price-font-weight: normal;
    --product-line-item-line-error-color: var(--site-red);
    --product-line-item-font-family-bold: var(--default-font-family);
    --product-line-item-discount-text-color: var(--site-red);
    --product-line-item-border-radius: 0;
    /* Checkout Variables */
    --checkout-my-bag-colour: var(--site-accent);
    --checkout-active-section-colour: var(--site-accent);
}
