@font-face {
    font-family: RedHatDisplay;
    src: url("/Fonts/RedHatDisplay-Regular.woff2") format("woff2");
}

@font-face {
    font-family: RedHatDisplay;
    src: url("/Fonts/RedHatDisplay-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: RedHatDisplay;
    src: url("/Fonts/RedHatDisplay-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
